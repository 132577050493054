import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import { numberToMoneyFormat } from "../../app.utils";
import deviceStore from "../../store/deviceStore";
import planStore from "../../store/planStore";
import AppLayout from "../../components/shared/AppLayout";
import successIcon from "../../assets/images/success-icon2.png";

const PageActivationSuccess = () => {
    const devicePlanPrice = deviceStore.devicePlanPrice;
    const deviceImeiSn = deviceStore.deviceImei;

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-activation-success">

                <div className="activation-success">
                    <div className="container">

                        <div className="activation-success__header">
                            <h2 className="activation-success__status">
                                <img className="activation-success__status-icon img-fluid" src={successIcon} alt="icon" width="88" />
                                <span className="activation-success__status-text text-bold">
                                    <FormattedMessage id="activation.success.title" />
                                </span>
                            </h2>
                            <p>
                                <FormattedMessage id="activation.success.desc" />
                            </p>
                        </div>

                        <div className="activation-success__device-details">
                            <ul className="activation-success__device-list">
                                <li className="activation-success__device-item">
                                    <span className="activation-success__device-info">
                                        <span>
                                            <FormattedMessage id="activation.success.imeiNumber" />
                                        </span>
                                        <span>
                                            <b>{deviceImeiSn}</b>
                                        </span>
                                    </span>
                                </li>
                                <li className="activation-success__device-item">
                                    <span className="activation-success__device-info">
                                        <span>
                                            <FormattedMessage id="activation.success.planDuration" />
                                        </span>
                                        <span>
                                            <b>{planStore.productTenure} {planStore.productTenureType}</b>
                                        </span>
                                    </span>
                                </li>
                                <li className="activation-success__device-item">
                                    <span className="activation-success__device-info">
                                        <span>
                                            <FormattedMessage id="activation.success.planAmount" />
                                        </span>
                                        <span>
                                            <b>{planStore.currency} {numberToMoneyFormat(devicePlanPrice)}</b>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageActivationSuccess;
